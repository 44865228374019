<template>
    <app-wrapper>
        <template v-slot:child>
            <!-- Top content-->
            <InvoiceHeader
                :contentType="'previewInvoice'"
                :invoiceNumber="invoice.number"
                :invoiceStatus="invoice.status"
                :submit="createInvoice"
                :id="'0'"
                :invoiceForm="invoiceFormRef"
                :invoiceTemplate="invoice.template"
                :invoice="invoice"
                :shareUrl="state.shareUrl"
                @on-pay-invoice="payInvoice"
            />

            <CreateEntity :closeModal="() => toggleCreateEntity()" />

            <template v-if="isLoading"><Spinner /></template>

            <div v-if="invoice?.number" class="w-100-l w-90 center pv4-l pv3 mini-spacing">
                <div class="flex flex-wrap justify-between w-90 center">
                    <div class="w-60-l w-100 mb4 mb0-l">
                        <!-- Customer, amount paid date -->
                        <div class="flex flex-wrap justify-between items-center pb3">
                            <div>
                                <div class="fw6 pb2">Customer</div>
                                <div class="f4 b" style="color: #132c8c">{{ invoice.customer.name }}</div>
                            </div>
                            <div class="flex items-center pt0-l pt4">
                                <div v-if="$route.fullPath.startsWith('/invoice')" class="mr4">
                                    <div class="fw6 pb2">Amount paid</div>
                                    <div class="f4 b" style="color: #132c8c">
                                        {{ formatAmount(invoice.initial_deposit, invoice?.currency || 'NGN') }}
                                    </div>
                                </div>
                                <div>
                                    <div class="fw6 pb2">Date</div>
                                    <div class="f4 b" style="color: #132c8c">
                                        {{ formatDate(invoice.dateIssued, 'DD/MM/YYYY') }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- status -->
                        <div>
                            <div class="flex items-center pt2 pb4">
                                <div class="mr2 fw5">Status</div>
                                <invoice-status :invoice="invoice" />
                            </div>
                        </div>

                        <!-- Invoive  -->
                        <div class="box-border pa4-l pa3 mb4" style="background: #fbfcfe">
                            <div class="flex flex-wrap items-center justify-between">
                                <div class="flex items-center">
                                    <img :src="require('@/assets/images/file-text.svg')" alt="" />
                                    <div class="fw5 f5 ml2">{{ $route.fullPath.startsWith('/invoice') ? 'Approve creation of invoice' : 'Create / Approve quotation' }}</div>
                                </div>
                                <div class="pt0-l pt3">
                                    <router-link
                                        v-if="$route.fullPath.startsWith('/invoice') && invoice.status === 'draft'"
                                        :to="{ name: 'InvoiceEdit', params: { id: invoice._id } }"
                                        style="color: #132c8c"
                                    >
                                        <button class="btn2" :disabled="state.processing || invoice.status !== 'draft'">
                                            Edit
                                        </button>
                                    </router-link>
                                    <router-link
                                        v-if="$route.fullPath.startsWith('/quotations') && (invoice.status === 'draft' || invoice.status === 'pending')"
                                        :to="{ name: 'EditQuote', params: { id: invoice._id } }"
                                        style="color: #132c8c"
                                    >
                                        <button class="btn2" :disabled="state.processing || invoice.status === 'approved'">
                                            Edit
                                        </button>
                                    </router-link>
                                    <button
                                        v-if="$route.fullPath.startsWith('/invoice') && !invoice.status?.includes('paid')"
                                        @click.prevent="createInvoice('create')"
                                        class="ml3-l ml2"
                                        :disabled="state.processing || invoice.status !== 'draft'"
                                    >
                                        {{ invoice.status === 'draft' ? 'Approve' : 'Invoice created' }}
                                    </button>
                                    <button
                                        v-if="$route.fullPath.startsWith('/quotation') && invoice.status === 'draft'"
                                        @click.prevent="confirmQuote(invoice)"
                                        class="ml3-l ml2"
                                        :disabled="state.processing"
                                    >
                                        Create
                                    </button>
                                    <button
                                        v-if="$route.fullPath.startsWith('/quotation') && invoice.status === 'pending'"
                                        @click.prevent="approveQuote(invoice)"
                                        class="ml3-l ml2"
                                        :disabled="state.processing"
                                    >
                                        Convert to Invoice
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!--Create Invoive  -->
                        <div class="box-border pa4-l pa3 mb4" style="background: #fbfcfe">
                            <div class="flex flex-wrap items-center justify-between">
                                <div class="flex items-center">
                                    <img :src="require('@/assets/images/fluent_payment.svg')" alt="" />
                                    <div class="ml2">
                                        <div class="fw5 f5">Share {{ $route.fullPath.startsWith('/invoice') ? 'invoice' : 'quotation' }}</div>
                                        <div v-if="$route.fullPath.startsWith('/invoice')" class="flex items-center pt2">
                                            <toggle-switch :checked="invoice.reminder" @setCheckValue="setCheckValue" />
                                            <div class="fw5 ml2" style="color: #132c8c">
                                                Schedule reminders to get paid 3x faster
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt3 pt0-l scrollmenu flex">
                                    <button
                                        @click.prevent="createInvoice('download')"
                                        class="btn2"
                                        :disabled="state.processing || invoice.status === 'draft'"
                                    >
                                        Download
                                    </button>
                                   <!-- <span   v-if=" invoice.status === 'part_payment' ||  invoice.status === 'awaiting_payment'">
                                    <button
                                        class="dropdown-togglex btn1 ml2"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Send <font-awesome-icon icon="fa-solid fa-caret-down" class="" />
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="cursor: pointer">
                                        <li
                                            @click.prevent="createInvoice('send')"
                                            class="ml2"
                                            :disabled="state.processing || invoice.status === 'draft'"
                                        >
                                            Share via Email
                                        </li>
                                        <li
                                            @click="openWhatsapp(invoice, shareUrl)"
                                            class="ml2 flex"
                                            style="gap: 3px"
                                            :disabled="state.processing || invoice.status === 'draft' || state.shareUrl === ' '"
                                        >
                                            <span>Share via Whatsapp</span
                                            ><span><img src="@/assets/images/whatsapp.svg" width="15" /></span>
                                        </li>
                                    </ul>
                                   </span> -->
                                   <button
                                        @click.prevent="createInvoice('send')"
                                        class="ml3-l ml2"
                                        :disabled="state.processing || invoice.status === 'draft'"
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div>
                            <select name="template" v-model="invoice.template">
                                <option value="default">Default Template</option>
                                <option value="letterhead">Letterhead Template</option>
                                <option value="diamond">Diamond Template</option>
                                <option value="gold">Gold Template</option>
                                <option value="silver">Silver Template</option>
                                <option value="platinum">Platinum Template</option>
                            </select>
                        </div>
                        <div v-if="invoice.template == 'default'" ref="invoiceFormRef">
                            <div class="box-border pv4" style="background: #fbfcfe">
                                <div class="pa3-l pa2 box-border" style="background: white">
                                    <div class="flex flex-wrap justify-between pv3">
                                        <div class="font-w1 pb3 pb0-l">Invoice No {{ invoice.number }}</div>
                                        <div class="flex">
                                            <div>Date issued: {{ formatDate(invoice.dateIssued) }}</div>
                                            <div class="pl3">Date due: {{ formatDate(invoice.dateDue) }}</div>
                                        </div>
                                    </div>
                                    <div class="pa3" style="background: #f7f9fd">
                                        <div class="flex items-center justify-between pb2">
                                            <div>
                                                <div class="f3 b pb2">
                                                    Invoice
                                                    <invoice-status :invoice="invoice" />
                                                </div>
                                                <div>{{ invoice.description }}</div>
                                            </div>
                                            <div class="db-l dn">
                                                <img src="require('@/assets/images/BA-large.svg')" alt="" />
                                            </div>
                                        </div>
                                        <div class="flex flex-wrap justify-between bill-section">
                                            <div class="pv3">
                                                <div class="b pb2 bill-to" style="color: #132c8c">Bill to</div>
                                                <div>
                                                    <div class="pb2 b">{{ invoice.customer?.name }}</div>
                                                    <div class="pb2">{{ invoice.customer?.address }}</div>
                                                    <div class="pb2">{{ invoice.customer?.country }}</div>
                                                    <div class="pb2">{{ invoice.customer?.email }}</div>
                                                    <div class="pb2">{{ invoice.customer?.phone }}</div>
                                                </div>
                                            </div>
                                            <div class="pv3 tr-lx tl">
                                                <div class="b pb2 bill-from" style="color: #132c8c">Bill from</div>
                                                <div class="flex justify-center items-center">
                                                    <div ref="companyLogoWrapper" v-if="orgImage">
                                                        <img
                                                            ref="companyLogo"
                                                            :src="orgImage"
                                                            alt=""
                                                            style="
                                                                object-position: center;
                                                                object-fit: cover;
                                                                width: 60px;
                                                                height: 60px;
                                                            "
                                                            class="rounded-circle"
                                                        />
                                                    </div>
                                                    <div class="org-img-placeholder" v-else>
                                                        <span>{{ orgInitials }}</span>
                                                    </div>
                                                    <div>
                                                        <div class="pb2x b">{{ invoice.business?.name }}</div>
                                                        <div class="pb2">{{ invoice.business?.address }}</div>
                                                        <div class="pb2">{{ invoice.business?.email }}</div>
                                                        <div class="pb2">{{ invoice.business?.phone }}</div>
                                                        <div class="pb2">{{ invoice.business?.tin }}</div>
                                                        <div class="pb2">{{ invoice.business?.rcNumber }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pa3-l pa2">
                                        <div class="flex justify-between pv3 box-border-bottom font-w1">
                                            <div class="w-30">Item</div>
                                            <div class="w-60 flex justify-between">
                                                <div class="w-30 tl">Cost</div>
                                                <div class="w-30 tc">Qty/Unit</div>
                                                <div class="w-30 tr">Price</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="item of invoice.items" v-bind:key="item.id" class="pa3-l pa2">
                                        <div class="flex justify-between pb3 box-border-bottom">
                                            <div class="w-30">
                                                <div class="b">{{ item.name }}</div>
                                                <div v-if="item.notes" class="text-muted" style="font-size: 0.9em">
                                                    {{ item.notes }}
                                                </div>
                                            </div>
                                            <div class="w-60 flex justify-between">
                                                <div class=" tl w-100">{{ item.price }}</div>
                                                <div class=" tc w-100">{{ item.quantity }} {{ item.measurement }}</div>
                                                <div class=" tr w-100">
                                                    <div>
                                                        {{
                                                            formatAmount(
                                                                item.total || item.price * item.quantity,
                                                                invoice.currency || $store.state.Settings?.currency
                                                            )
                                                        }}
                                                    </div>
                                                    <div v-if="item.discount > 0" class="text-muted" style="font-size: 0.7em;">
                                                        {{ item.discount }} {{ item.discountType == 'percentage' ? '%' :  item.discountType}} discount
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Subtotal -->
                                    <div class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Subtotal</div>
                                                <div class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            invoice.subtotal,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Discount -->
                                    <div v-if="invoice.hasDiscount" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Discount</div>
                                                <div v-if="invoice.discountType == 'fixed'" class="w-30 tr" style="color: #D2042D">
                                                   - {{
                                                        formatAmount(
                                                            invoice.discount,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr" style="color: #D2042D">-{{ invoice.discount }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="invoice.hasCommission" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Commission</div>
                                                <div v-if="invoice.commissionType == 'fixed'" class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            invoice.commission,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr">{{ invoice.commission }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="invoice.hasShipping" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Shipping</div>
                                                <div v-if="invoice.shippingType == 'fixed'" class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            invoice.shipping,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr">{{ invoice.shipping }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="invoice.hasTax" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Tax</div>
                                                <div v-if="invoice.taxType == 'fixed'" class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            invoice.tax,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr">{{ invoice.tax }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="invoice.hasOthersCharges" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">{{ invoice.others }}</div>
                                                <div class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            invoice.othersCharges,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="ph3-l ph2">
                                        <div class="flex justify-between pb5 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Total</div>
                                                <div class="w-30 tr b" style="color: #132c8c">
                                                    {{
                                                        formatAmount(
                                                            invoice.totalAmount,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="invoice?.deposit && invoice?.deposit > 0" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom b">
                                                <div class="w-30 tl">Amount Paid</div>
                                                <div class="w-30 tr" style="color: #132c8c">
                                                    {{
                                                        formatAmount(
                                                            invoice?.deposit || 0,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="invoice?.deposit && invoice?.deposit > 0" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom b">
                                                <div class="w-30 tl text-danger">Outstanding</div>
                                                <div class="w-30 tr text-danger">
                                                    {{
                                                        formatAmount(
                                                            invoice.totalAmount - (invoice?.deposit || 0),
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="lh-copy pv3"
                                        style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #e3e8ee"
                                    >
                                        All amounts are in {{ getCurrencyName(invoice.currency) }}. Please make the payment before
                                        {{ formatDate(invoice.dateDue) }}.
                                    </div>
                                    <div
                                        v-if="invoice.notes"
                                        class="lh-copy pv3"
                                        style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #e3e8ee"
                                    >
                                        <div class="font-w1">Notes:</div>
                                        {{ invoice.notes ? invoice.notes : '' }}
                                    </div>
                                </div>
                            </div>

                            <div class="box-border mv4" style="background: #fbfcfe">
                                <div class="flex justify-between box-border-bottom pa3">
                                    <div>Selected payment option</div>
                                </div>
                                <div v-for="item of invoice.bankDetails" v-bind:key="item.bankAccountNumber" class="pa3">
                                    <div class="flex flex-wrap justify-between items-center pa3 box-border">
                                        <div>
                                            <div class="pb2 b">Bank Transfer</div>
                                            <div class="pb2">Pay by making a bank transfer to the recievers bank account</div>
                                            <div class="flex justify-between">
                                                <div class="w-30">Bank Name:</div>
                                                <div class="w-70 b">{{ item.bankName }}</div>
                                            </div>
                                            <div class="flex justify-between">
                                                <div class="w-30">Account Number:</div>
                                                <div class="w-70 b">{{ item.bankAccountNumber }}</div>
                                            </div>
                                            <div class="flex justify-between">
                                                <div class="w-30">Account Name:</div>
                                                <div class="w-70 b">{{ item.bankAccountName }}</div>
                                            </div>
                                        </div>
                                        <div class="pt0-l pt3">
                                            <img src="require('@/assets/images/bank.svg')" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div v-if="invoice?.paystackLink" class="pa3">
                                    <div class="flex flex-wrap justify-between items-center pa3 box-border copy-text-wrapper">
                                        <div>
                                            <div class="pb2 b">Paystack</div>
                                            <div>Pay through the secure paystack API</div>
                                            <div class="flex flex-wrap justify-between items-center pb2">
                                                <a :href="invoice.paystackLink" target="_blank" class="pr2">{{
                                                    invoice.paystackLink
                                                }}</a>
                                                <button @click="doCopy" class="pl0 pr0 pb0 pt0">
                                                    <span class="icon">
                                                        <font-awesome-icon icon="fa-solid fa-copy" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="pt0-l pt3">
                                            <img :src="require('@/assets/images/paystack.svg')" alt="paystack logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DiamondInvoice v-else-if="invoice.template == 'diamond'" :invoice="invoice" :color="invoice.color" :font="invoice.font" />
                        <GoldInvoice v-else-if="invoice.template == 'gold'" :invoice="invoice" :color="invoice.color" :font="invoice.font" />
                        <SilverInvoice v-else-if="invoice.template == 'silver'" :invoice="invoice" :color="invoice.color" :font="invoice.font" />
                        <PlatinumInvoice v-else-if="invoice.template == 'platinum'" :invoice="invoice" :color="invoice.color" :font="invoice.font" />
                        <letter-head-invoice v-else :invoice="invoice" :color="invoice.color" :font="invoice.font" />
                    </div>

                    <div class="w-34-l w-100">
                        <div class="box-border" style="background: #fbfcfe">
                            <div class="box-border-bottom pa3">
                                <div>Send this {{ $route.fullPath.startsWith('/invoice') ? 'invoice' : 'quotation' }} to this contact</div>
                            </div>
                            <div>
                                <div class="flex justify-between ph3 pt3">
                                    <div>Entity</div>
                                    <!-- <div style="color: #132C8C;">Change</div> -->
                                </div>
                                <div class="pa3">
                                    <div class="box-border pa2 flex items-center">
                                        <img src="require('@/assets/images/Avatar.png')" alt="" />
                                        <div class="pl2">{{ invoice.customer?.name }}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="pa3">
                                <div class="flex justify-between pt3" style="border-top: 1px solid #e3e8ee;">
                                    <div>Other recipients:</div>
                                    <a class="b"  style="color: #132C8C; cursor: pointer" @click.prevent="toggleCreateEntity(true)">+ Add Recipients</a>
                                </div>
                            </div> -->
                        </div>

                        <div class="box-border mt4" style="background: #fbfcfe">
                            <div class="box-border-bottom pa3">
                                <div>Message to contact</div>
                            </div>
                            <div class="flex flex-column pa3">
                                <label class="pb3" for="">Subject</label>
                                <input
                                    type="text"
                                    class="pa2 box-border"
                                    name=""
                                    placeholder="John ogaga invoice #001 from Business A"
                                    id=""
                                />
                            </div>
                            <div class="flex flex-column pa3">
                                <label class="pb3" for="">Message</label>
                                <textarea
                                    name=""
                                    id=""
                                    cols="20"
                                    rows="10"
                                    class="box-border pa2"
                                    placeholder="Enter Message here"
                                >
                                </textarea>
                            </div>
                        </div>

                        <div v-if="invoice?.status !== 'draft'" class="box-border mt4" style="background: #fbfcfe">
                            <div class="flex pa3 justify-between items-center box-border-bottom">
                                <div class="w-40 b">Shareable Link</div>
                                <div class="w-20 flex justify-between items-center b tr">
                                    <!-- <span @click.prevent="copyShareLink(state.shareUrl)" style="cursor:pointer">
                                    Copy <img src="require('@/assets/images/copy.svg')" class="tr" alt="" />
                                </span> -->
                                    <a
                                        :href="'whatsapp://send?text=' + state.shareUrl"
                                        data-action="share/whatsapp/share"
                                        class="share-btn-mobile"
                                    >
                                        Share
                                    </a>
                                    <a href="" class="share-btn-desktop" @click.prevent="copyShareLink(state.shareUrl)"> Copy </a>
                                </div>
                            </div>
                            <div class="pa3">
                                <div class="share-link-container box-border pa3">
                                    {{ state.shareUrl }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, watch, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { copyText } from 'vue3-clipboard'
import Swal from 'sweetalert2'
import { formatAmount, formatQuantity, getCurrencyName, getDaysFromToday, formatDate } from '@/utils/lib'
import AppWrapper from '@/layout/AppWrapper'
import InvoiceHeader from '@/views/Invoice/widgets/InvoiceHeader'
import InvoiceStatus from '@/views/Invoice/widgets/InvoiceStatus'
import Pagination from '@/components/Pagination'
import CreateEntity from '@/components/CreateEntity'
import ToggleSwitch from '@/components/ToggleSwitch'

import Spinner from '@/components/Spinner'
import LetterHeadInvoice from '@/components/LetterHeadInvoice.vue'
import DiamondInvoice from '@/components/DiamondInvoice.vue'
import GoldInvoice from '@/components/GoldInvoice.vue'
import SilverInvoice from '@/components/SilverInvoice.vue'
import PlatinumInvoice from '@/components/PlatinumInvoice.vue'
// import Filter from '@/components/Filter'

export default {
    name: 'InvoicePreviewAndSend',
    components: {
        AppWrapper,
        InvoiceHeader,
        InvoiceStatus,
        Pagination,
        Spinner,
        LetterHeadInvoice,
        CreateEntity,
        ToggleSwitch,
        DiamondInvoice,
        GoldInvoice,
        SilverInvoice,
        PlatinumInvoice
    },

    setup() {
        const router = useRouter()
        const store = useStore()
        // const productPages = computed(() => store?.state.Inventory.productPages)

        const isLoading = computed(() => store?.state.Invoice.isLoading)
        const orgData = computed(() => store?.state?.Auth?.userData)
        const invoice = computed(() => store?.state.Invoice.invoice)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const route = useRoute()

        const initialState = {
            isLoading: true,
            processing: false,
            invoiceId: route.params.id,
            shareUrl: '',
            invoice: null,
        }
        const invoiceFormRef = ref(null)
        const state = reactive(initialState)

        const copyShareLink = (url) => {
            copyText(url, undefined, (error, event) => {
                if (error) {
                    store.dispatch('Alert/setAlert', {
                        message: `Could not copy receipt link`,
                        status: false,
                    })
                    console.log(error)
                } else {
                    store.dispatch('Alert/setAlert', {
                        message: `Invoice link copied`,
                        status: true,
                    })
                    // console.log(event)
                }
            })
        }

        const doCopy = () => {
            copyText(invoice.value.paystackLink, undefined, (error, event) => {
                if (!error) {
                    let copyText = document.querySelector('.copy-text-wrapper')
                    copyText.classList.add('active')
                    setTimeout(function () {
                        copyText.classList.remove('active')
                    }, 2500)
                }
            })
        }

        // watch for changes for invoice
        watch(invoice, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                // console.log('invoice changed', newValue)
            }
        })

        const toggleCreateEntity = (payload) => {
            store.dispatch('Entity/toggleCreateEntity', payload)
            //if (payload && invoice.value.customer.name) store.dispatch('Entity/setEntityName', state.entity)
        }

        const processInvoiceStatus = (invoice) => {
            if (invoice.initial_deposit === invoice.totalAmount) {
                invoice.status = ['paid']
            } else if (invoice.initial_deposit > 0) {
                invoice.status = ['part_payment']
            } else {
                invoice.status = 'awaiting_payment'
            }

            return invoice
        }

        const payInvoice = (invoice) => {
            invoice.status = ['paid']
            invoice.sendMail = true
            invoice.mailSent = false
            store.dispatch('Invoice/updateInvoice', invoice)
        }

        const openWhatsapp = (inv, shareUrl) => {
            const message = `
Hi ${inv.customer.name.split(' ')[0]},
Your invoice ${inv.reference} for the sum of ${inv.totalAmount}${inv.currency} on ${inv.dateIssued} from ${
                inv.business.name
            } is ready for you. View it here: ${shareUrl}
If you have any questions, contact us at ${inv.business.email} or ${inv.business.phone}.
Thanks for choosing ${inv.business.name}.
Best regards, ${inv.business.name}`
            const encodedMessage = encodeURIComponent(message).replace(/%0A/g, '%0A%0A')
            const whatsappURL = `https://wa.me/+234${(inv.customer.phone).replace('234', '')}?text=${encodedMessage}`
            window.open(whatsappURL, '_blank')
        }

        const createInvoice = (type) => {
            state.processing = true
            const invoice = processInvoiceStatus(state.invoice)
            if (type === 'send') {
                const storeAction = route.fullPath.startsWith('/quotations')
                    ? 'Invoice/createAndSendQuotation'
                    : 'Invoice/createAndSendInvoice'

                store.dispatch(storeAction, invoice).then(() => (state.processing = false))
            } else if (type === 'download') {
                const storeAction = route.fullPath.startsWith('/quotations')
                    ? 'Invoice/createAndDownloadQuotation'
                    : 'Invoice/createAndDownloadInvoice'

                store.dispatch(storeAction, invoice).then((response) => {
                    if (response?.data?.error) {
                        console.error(response.data.error)
                    }

                    const date = formatDate(new Date(), 'DDMMYYYY')
                    const fileURL = window.URL.createObjectURL(new Blob([response]))
                    const fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    const fileName = `${orgData.value?.org}-${date}`
                    const fileTitle = route.fullPath.startsWith('/quotations') ? 'quotation' : 'invoice'
                    fileLink.setAttribute('download', `${fileTitle}-${invoice?.number || invoice?.reference}-${fileName}.pdf`)
                    fileLink.setAttribute('target', '_blank')
                    document.body.appendChild(fileLink)
                    fileLink.click()
                    fileLink.remove()

                    let routePath = route.fullPath.startsWith('/quotations')
                        ? 'Quotations'
                        : 'InvoiceSummary'
                    router.push({ name: routePath })
                })
            } else if (type === 'create') {
                store.dispatch('Invoice/updateInvoice', invoice).then(() => (state.processing = false))
            }
        }

        const setCheckValue = (value) => {
            state.invoice.reminder = value
        }

        const confirmQuote = quote => {
           store.dispatch('Invoice/confirmQuote', quote).then((data) => {
                        if (data.status) {
                            invoice.status = 'pending'
                        } else {
                            invoice.status = 'draft'
                        }
                    }).finally(() => {
                        state.processing = false
                    }) 
        }

        const approveQuote = quote => {
            Swal.fire({
                title: `Approve Quotation`,
                text: `Are you sure you want to approve this quote - ${quote.reference}?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, Approve!',
                denyButtonText: `No, cancel!`
            }).then(result => {
                if (result.isConfirmed) {
                    state.processing = true
                    const invoice = processInvoiceStatus(state.invoice)
                    invoice.status = 'awaiting_payment'
                    store.dispatch('Invoice/approveQuote', quote._id).then((data) => {
                        if (data.status) {
                            invoice.status = 'approved'
                        } else {
                            invoice.status = 'pending'
                        }
                    }).finally(() => {
                        state.processing = false
                        router.push('/invoice/summary');
                    })
                }
            })
        }

        onMounted(async () => {
            const storeAction = route.fullPath.startsWith('/quotations')
                ? 'Invoice/getQuotation'
                : 'Invoice/getInvoice'

            await store.dispatch(storeAction, route.params.id).then((data) => {
                state.invoice = data
            })

            await store.dispatch('Settings/getAccountSettings').then((data) => {
                state.invoice.template = data?.data?.invoice?.template || 'letterhead'
                state.invoice.color = data?.data?.invoice?.color || '#132C8C'
                state.invoice.font = data?.data?.invoice?.font || 'Arial'
            })
        })

        return {
            isLoading,
            invoice,
            formatAmount,
            formatQuantity,
            getCurrencyName,
            getDaysFromToday,
            formatDate,
            state,
            toggleCreateEntity,
            invoiceFormRef,
            orgInitials,
            orgImage,
            copyShareLink,
            createInvoice,
            payInvoice,
            doCopy,
            setCheckValue,
            openWhatsapp,
            approveQuote,
            confirmQuote,
        }
    },
}
</script>

<style>
.directional-up {
    width: 15px;
    height: 15px;
}
.sort1 {
    padding: 6px;
    cursor: pointer;
}
.sort1:hover {
    background-color: rgb(237, 240, 253);
    color: #132c8c;
    border-radius: 5px;
}
.org-img-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(215, 222, 250);
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 25px;
    color: rgb(19, 44, 140);
    margin-right: 5px;
}
.invoice-status-badge {
    /* background: #FEEAD7;  */
    /* color: #FB8B24;  */
    border-radius: 20px;
}
/* @media (max-width: 800px) {
    .flex.bill-section {
        flex-direction: row-reverse;
    }
} */

/* .bill-to{
    display: block;
} */
.share-link-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.share-btn-mobile {
    display: none;
}
.share-btn-desktop {
    display: inline;
}
@media screen and (max-width: 1261px) {
    .share-btn-mobile {
        display: inline;
    }
    .share-btn-desktop {
        display: none;
    }
}

.copy-text-wrapper button {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
    cursor: pointer;
    background-color: unset !important;
    color: unset !important;
}

.copy-text-wrapper button:active {
    background: #809ce2;
}
.copy-text-wrapper button:before {
    content: 'Copied';
    position: absolute;
    top: -45px;
    right: 0px;
    background: #5c81dc;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
}
.copy-text-wrapper button:after {
    content: '';
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #5c81dc;
    transform: rotate(45deg);
    display: none;
}
.copy-text-wrapper.active button:before,
.copy-text-wrapper.active button:after {
    display: block;
}
</style>
